import { filter, map, entries, isEmpty } from 'lodash';
import { convertDateStringToISODate } from './dateUtil';

export const buildFiltersString = (columns, filtersObj) => {

    if(isEmpty(filtersObj)) {
        return '';
    }

    const mapToFilterCriteria = pair => {
        const columnData = filter(columns, col => col.column === pair.key)[0] ?? filter(columns, col => col.type === "date")[0];
        const { column: columnId, type: columnType, valueType } = columnData;

        switch(columnType) {
            case 'input':
                if (valueType === 'string') {
                    return `${columnId}@=${pair.value}`;
                } else {
                    return `${columnId}==${pair.value}`;
                }
                
            case 'date':
                return pair.key ==='from' ? `${columnId}>=${convertDateStringToISODate(pair.value)}` : `${columnId}<=${convertDateStringToISODate(pair.value)}`;
            case 'multiselect':
                return `${columnId}==${Array.isArray(pair.value) ? pair.value.join('|') : pair.value}`;
            default:
                return `${columnId}==${pair.value}`;
        }      
    }
    const filtersKeyToVal = map(entries(filtersObj), ([key, value]) => ({key,value}));
    return map(filtersKeyToVal, mapToFilterCriteria).join(',');
}


export const validateFilterInput = filterEl => {
    const filterValueType = filterEl.getAttribute("value-type");
    const filterValue = filterEl.value;

    if (filterValueType === 'number') {
        if (!(/^\d+$/.test(filterValue) || filterValue === '') || parseInt(filterValue) > 4294967295) {
            filterEl.value = '';
            return false;
        }
    }

    return true;
}